main {
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  line-height: 1.6;

  @include themify($themes) {
    color: themed('colorText');
  }
}

* {
  box-sizing: inherit;
}

ul,
ol {
  padding-left: 15px;
  margin-bottom: 0;
}

a {
  color: $color-blue;
  transition: all 0.3s;

  &:hover {
    text-decoration: none;
    color: $color-blue-hover;
  }
}

img {
  width: 100%;
}

$spaceamounts: (0, 1, 2, 3, 4, 5);
$sides: (top, bottom, left, right);

@each $space in $spaceamounts {
  .m-#{$space} {
    margin: (0.5em * $space) !important;
  }

  .p-#{$space} {
    padding: (0.5em * $space) !important;
  }

  .mx-#{$space} {
    margin-left: (0.5em * $space) !important;
    margin-right: (0.5em * $space) !important;
  }

  .px-#{$space} {
    padding-left: (0.5em * $space) !important;
    padding-right: (0.5em * $space) !important;
  }

  .my-#{$space} {
    margin-top: (0.5em * $space) !important;
    margin-bottom: (0.5em * $space) !important;
  }

  .py-#{$space} {
    padding-top: (0.5em * $space) !important;
    padding-bottom: (0.5em * $space) !important;
  }

  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: (0.5em * $space) !important;
    }

    .p#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: (0.5em * $space) !important;
    }
  }
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.px-auto {
  padding-left: auto;
  padding-right: auto;
}
