.pagination {
  li {
    padding: 10px;

    &.active {
      border: 1px solid #000;

      a {
        border: none;
        outline: none;
      }
    }
  }
}
